import BluetoothDisabledOutlinedIcon from "@mui/icons-material/BluetoothDisabledOutlined";
import BluetoothSearchingOutlinedIcon from "@mui/icons-material/BluetoothSearchingOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { Button, Fab, ThemeProvider, Tooltip, Typography } from "@mui/material";
import path from "path";
import React, { useEffect } from "react";
import styled from "styled-components";
import "./App.css";
import NoBluetoothSupport from "./components/connect-info";
// import FirmwareUpdater from "./components/firmware-update";
import PlanterData from "./components/planter-data";
import PlanterName from "./components/planter-name";
import { BluetoothContextProvider, useBluetooth } from "./hooks/use-bluetooth";
import { theme } from "./styles/theme";

require("dotenv").config({ path: path.resolve(__dirname, "./.env") });

const AppWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px;
  width: 80%;
`;

const LogoImage = styled.img.attrs((props) => ({
  alt: "logo",
  src: process.env.PUBLIC_URL + "/logo-uneti-labs.png",
}))``;

function App() {
  // const pin = new URLSearchParams(window.location.search).get("pin");
  const bt = useBluetooth();

  useEffect(() => {
    if (bt.status && !bt.status.init) {
      bt.setTime();
    }
  }, [bt, bt.status]);

  // console.log(pin);
  return (
    <AppWrapper>
      <PageWrapper>
        <LogoImage
          style={{
            maxHeight: bt.state.connected ? 100 : 400,
          }}
        />
        {!navigator.bluetooth && <NoBluetoothSupport />}
        {navigator.bluetooth && (
          <>
            {/*<FirmwareUpdater />*/}
            {bt.state.connected ? (
              <>
                <PlanterName />
                <PlanterData />

                <Tooltip title="Restart">
                  <Fab
                    color="primary"
                    aria-label="restart"
                    style={{ position: "absolute", bottom: 16, right: 88 }}
                    onClick={() => bt.restart()}
                  >
                    <RestartAltOutlinedIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title="Disconnect">
                  <Fab
                    color="secondary"
                    aria-label="disconnect"
                    style={{ position: "absolute", bottom: 16, right: 16 }}
                    onClick={bt.disconnect}
                  >
                    <BluetoothDisabledOutlinedIcon />
                  </Fab>
                </Tooltip>
              </>
            ) : (
              <>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <Typography>
                    Great to see you here with your Smart Planter!
                  </Typography>
                  <Typography>Let's customize it and get it going!</Typography>
                </div>
                <Button
                  variant="contained"
                  onClick={bt.connect}
                  startIcon={<BluetoothSearchingOutlinedIcon />}
                >
                  Connect
                </Button>
              </>
            )}
          </>
        )}
      </PageWrapper>
    </AppWrapper>
  );
}

const SmartPlanterApp = () => (
  <ThemeProvider theme={theme}>
    <BluetoothContextProvider>
      <App />
    </BluetoothContextProvider>
  </ThemeProvider>
);

export default SmartPlanterApp;
