import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { IconButton, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useBluetooth } from "../hooks/use-bluetooth";

const isNameValid = (name: string | undefined) => {
  return name && name.length >= 4 && name.length <= 20;
};

const NameBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

function PlanterName() {
  const bt = useBluetooth();
  const [name, setName] = useState<string | undefined>();

  useEffect(() => {
    if (!name) {
      if (bt.state.connected) {
        setName(bt.deviceName);
      }
    } else {
      if (!bt.state.connected) {
        setName(undefined);
      }
    }
  }, [bt.state, bt.deviceName, name]);

  return bt.state.connected ? (
    <NameBlock>
      <Typography>Call me</Typography>
      <TextField
        variant="standard"
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{ marginLeft: 10, marginRight: 10 }}
      />
      <Tooltip title="Rename">
        <IconButton
          disabled={!isNameValid(name)}
          onClick={() => {
            if (name) bt.setConfig("NAME", name, true);
          }}
        >
          <ArrowForwardOutlinedIcon />
        </IconButton>
      </Tooltip>
    </NameBlock>
  ) : null;
}

export default PlanterName;
