import React from 'react'

export default function NoBluetoothSupport() {
    return (<>
        <p>
            Your device cannot use bluetooth yet
        </p>
        <p>
            Go to <a href="https://web.dev/bluetooth/">web.dev/bluetooth/</a> to learn how to enable bluetooth support on your device
        </p>
    </>);
}
