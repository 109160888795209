import { createContext, FunctionComponent, useContext, useState } from "react";
import hash from "hash.js";
import { DeviceStatus, UploadStatus } from "../types/types";
import { sleep } from "../util/util";

const serviceUuid =
  process.env.BLUETOOTH_SERVICE_UUID ?? "f0b25389-d841-43c2-8046-22b17893a2e3";
const configCharacteristicUuid =
  process.env.BLUETOOTH_CONFIG_UUID ?? "e4090688-48e8-40aa-b57c-9cc9429737a0";
const statusCharacteristicUuid =
  process.env.BLUETOOTH_STATUS_UUID ?? "a1dc4b32-a96a-4580-aaf8-0a23b00dad33";
const otaCharacteristicUuid =
  process.env.BLUETOOTH_OTA_UUID ?? "1a20e0ce-fc80-48f1-9098-c9dce0454024";

type Context = {
  connect: () => void;
  restart: () => void;
  setTime: () => void;
  disconnect: () => void;
  setConfig: (key: string, value: string, restart: boolean) => void;
  doOTA: (
    filename: string,
    fileData: ArrayBuffer,
    restart: boolean,
    setUploadStatus: React.Dispatch<React.SetStateAction<UploadStatus>>
  ) => Promise<void>;
  status?: DeviceStatus;
  deviceName: string;
  state: { connected: boolean; error: boolean };
};

const BluetoothContext = createContext<Context | null>(null);

export const BluetoothContextProvider: FunctionComponent = ({ children }) => {
  const [connection, setConnection] = useState<BluetoothRemoteGATTServer>();
  const [service, setService] = useState<BluetoothRemoteGATTService>();
  const [deviceName, setDeviceName] = useState("");
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState(false);
  const [status, setStatus] = useState<DeviceStatus | undefined>(undefined);
  const [statusCharacteristic, setStatusCharacteristic] =
    useState<BluetoothRemoteGATTCharacteristic>();
  const textEncoder = new TextEncoder();
  const textDecoder = new TextDecoder();
  const [rejectNotification, setRejectNotification] = useState(false);

  const connect = async () => {
    try {
      const device = await navigator.bluetooth.requestDevice({
        acceptAllDevices: false,
        filters: [{ services: [serviceUuid] }],
      });

      console.log("Connected", device);
      device.addEventListener("gattserverdisconnected", (ev: Event) => {
        console.log("Disconnecting!");
        setDeviceName("");
        setService(undefined);
        setConnection(undefined);
        setConnected(false);
      });

      setDeviceName(device?.name || "");
      const server = await device.gatt?.connect();
      if (server) {
        setConnection(server);
        const primaryService = await server.getPrimaryService(serviceUuid);
        if (primaryService) {
          setService(primaryService);
          console.log("Subscribing");
          await subscribeStatus(primaryService);
        }
      }
      setConnected(true);
      setError(false);
    } catch (e) {
      setError(true);
    }
  };

  const sendCommand = async (command: string) => {
    try {
      if (connection && service) {
        const configCharacteristic = await service.getCharacteristic(
          configCharacteristicUuid
        );
        await configCharacteristic.writeValue(textEncoder.encode(command));
      }
      setError(false);
    } catch (e) {
      setError(true);
    }
  };

  const sendOTAPart = async (command: string) => {
    try {
      if (connection && service) {
        (await service.getCharacteristic(otaCharacteristicUuid)).writeValue(
          textEncoder.encode(command)
        );
      } else {
        setError(true);
        return;
      }
      setError(false);
    } catch (e) {
      setError(true);
    }
  };

  const doOTA = async (
    filename: string,
    fileData: ArrayBuffer,
    restart: boolean,
    setUploadStatus: React.Dispatch<React.SetStateAction<UploadStatus>>
  ) => {
    try {
      const fileText = textDecoder.decode(fileData);
      const fileLen = fileText.length;
      const sha256 = hash.sha256().update(fileText).digest("hex");
      const firstPacket = "1:" + filename + ":" + fileLen + ":" + sha256 + ":";
      await sendOTAPart(firstPacket);
      setUploadStatus({ progress: 0, status: "Started" });
      for (let i = 0; i < fileLen; i += 500) {
        await sleep(500);
        const filePart = fileText.substring(i, i + 500);
        const filePacket = "2:" + filePart;
        await sendOTAPart(filePacket);
        setUploadStatus({
          progress: (100 * i) / fileLen,
          status: "In Progress",
        });
      }
      await sleep(500);
      await sendOTAPart(restart ? "3:" : "4:");
      setUploadStatus({ progress: 100, status: "Complete" });
      setError(false);
    } catch (e) {
      setError(true);
    }
  };

  const handleStatusChange = async (ev: Event) => {
    ev.preventDefault();
    if (rejectNotification) {
      setRejectNotification(false);
    }
    if (!ev.target || !(ev.target as BluetoothRemoteGATTCharacteristic).value)
      return;
    const readStatus = textDecoder.decode(
      (ev.target as BluetoothRemoteGATTCharacteristic).value
    );
    console.log(readStatus);
    try {
      setStatus(JSON.parse(readStatus) as DeviceStatus);
    } catch (err) {
      try {
        setRejectNotification(true);
        const fullStatus = textDecoder.decode(
          await (ev.target as BluetoothRemoteGATTCharacteristic).readValue()
        );
        setStatus(JSON.parse(fullStatus) as DeviceStatus);
      } catch (err) {
        // Do nothing
      }
    }
  };

  const subscribeStatus = async (service: BluetoothRemoteGATTService) => {
    try {
      const statusC = await service.getCharacteristic(statusCharacteristicUuid);
      console.log("Adding event listener!", service, statusC);
      statusC.startNotifications().then((statusC) => {
        setStatusCharacteristic(statusC);
        return statusC.addEventListener(
          "characteristicvaluechanged",
          handleStatusChange
        );
      });
      setError(false);
    } catch (e) {
      setError(true);
    }
  };

  const restart = async () => {
    try {
      if (connection && service) {
        await sendCommand("RESTART");
      }
      setError(false);
    } catch (e) {
      setError(true);
    }
  };

  const setTime = async () => {
    try {
      if (connection && service) {
        const dt = new Date();
        await sendCommand(
          `TIME ${dt.getUTCFullYear()}:${dt.getUTCMonth()}:${dt.getUTCDate()}:${dt.getUTCHours()}:${dt.getUTCMinutes()}:${dt.getUTCSeconds()}:00:00`
        );
      }
      setError(false);
    } catch (e) {
      setError(true);
    }
  };

  const disconnect = () => {
    if (connection) {
      console.log("Disconnecting!");
      connection.disconnect();
      statusCharacteristic &&
        statusCharacteristic.removeEventListener(
          "characteristicvaluechanged",
          handleStatusChange
        );
      setDeviceName("");
      setService(undefined);
      setConnection(undefined);
      setConnected(false);
    }
  };

  const setConfig = async (key: string, value: string, restart: boolean) => {
    try {
      const cmd = `CONFIG${restart ? "R" : ""} ${key} ${value}`;
      console.log("setting cfg", cmd);
      if (connection && service) {
        await sendCommand(cmd);
      }
      setTimeout(() => connect(), 2000);
      setError(false);
    } catch (e) {
      setError(true);
    }
  };

  return (
    <BluetoothContext.Provider
      value={{
        connect,
        restart,
        setTime,
        disconnect,
        setConfig,
        doOTA,
        status,
        deviceName,
        state: { connected, error },
      }}
    >
      {children}
    </BluetoothContext.Provider>
  );
};

export const useBluetooth = () => {
  const data = useContext(BluetoothContext);
  if (!data) {
    throw new Error("Must be used inside provider");
  }
  return data;
};
