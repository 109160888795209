import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import {
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  ToggleButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useBluetooth } from "../hooks/use-bluetooth";

function PlanterData() {
  const [configKey, setConfigKey] = useState<string>("");
  const [configValue, setConfigValue] = useState<string>("");
  const [showInfo, setShowInfo] = useState(false);
  const bt = useBluetooth();
  const isConfigValid = () =>
    configKey.length > 0 &&
    [
      "WIFI_SSID",
      "WIFI_PASS",
      "CODE",
      "NAME",
      "BUZZ_ON",
      "BUZZ_OFF",
      "BUZZ_CONF",
      "FACE_MODE",
      "IACT_TO",
    ].includes(configKey);
  return bt.status ? (
    <>
      <ToggleButton
        value="list"
        aria-label="list"
        selected={showInfo}
        onChange={() => {
          setShowInfo(!showInfo);
        }}
      >
        <ListOutlinedIcon />
      </ToggleButton>
      {showInfo ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                {Object.entries(bt.status).map((entry) => (
                  <TableRow
                    key={entry[0]}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {entry[0]}
                    </TableCell>
                    <TableCell align="right">
                      {JSON.stringify(entry[1])}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 20,
                alignItems: "center",
              }}
            >
              <Select
                value={configKey}
                label="Key"
                onChange={(e) => setConfigKey(e.target.value)}
                style={{ height: 22 }}
              >
                <MenuItem value={"NAME"}>Name</MenuItem>
                <MenuItem value={"WIFI_SSID"}>WiFi SSID</MenuItem>
                <MenuItem value={"WIFI_PASS"}>WiFi Password</MenuItem>
                <MenuItem value={"CODE"}>Code (Unused)</MenuItem>
                <MenuItem value={"BUZZ_ON"}>On Beep frequency</MenuItem>
                <MenuItem value={"BUZZ_OFF"}>Off Beep frequency</MenuItem>
                <MenuItem value={"BUZZ_CONF"}>
                  Config change Beep frequency
                </MenuItem>
                <MenuItem value={"FACE_MODE"}>
                  Set Face Mode (True/False)
                </MenuItem>
                <MenuItem value={"IACT_TO"}>
                  Inactivity timeout in milliseconds
                </MenuItem>
              </Select>
              <input
                onChange={(e) => setConfigValue(e.target.value)}
                value={configValue}
              ></input>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 10,
                alignItems: "center",
              }}
            >
              <Button
                disabled={!isConfigValid()}
                onClick={() => bt.setConfig(configKey, configValue, false)}
              >
                Set
              </Button>
              <Button
                disabled={!isConfigValid()}
                onClick={() => bt.setConfig(configKey, configValue, true)}
              >
                Set {"&"} Restart
              </Button>
            </div>
          </div>
        </>
      ) : null}
    </>
  ) : (
    <Typography>Waiting for data...</Typography>
  );
}

export default PlanterData;
